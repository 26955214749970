import axios from 'axios';

const getOrderSummaryBySupplierAndDateRange = (supplierId, startDate, endDate) => {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order_summary/search/by_supplier/${supplierId}/${startDate}/${endDate}/`)
}

const getOrderGrandTotalBySupplierAndDateRange = (supplier, startDate, endDate) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order_summary/grand_total/by_supplier/${supplier}/${startDate}/${endDate}/`);
}

export {
    getOrderSummaryBySupplierAndDateRange,
    getOrderGrandTotalBySupplierAndDateRange
}