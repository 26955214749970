import userCSS from "./Usermanagement.module.css";


function Adduser({ rowsData, deleteUserRows, handleChange }) {
  return rowsData.map((data, index) => {
    const { firstname, lastname, emailaddress, password, usertype } = data;

    return (
      <tr className={userCSS.TableMainBody} key={index}>
        <td>
          <label>First Name:</label>
          <input
            type="text"
            maxLength="50"
            value={firstname}
            onChange={(evnt) => handleChange(index, evnt)}
            name="firstname"
            className="form-control"
            placeholder="First Name"
          />
        </td>
        <td>
          <label>Last Name:</label>
          <input
            type="text"
            maxLength="50"
            value={lastname}
            onChange={(evnt) => handleChange(index, evnt)}
            name="lastname"
            className="form-control"
            placeholder="Last Name"
          />
        </td>
        <td>
          <label>Email:</label>
          <input
            type="email"
            maxLength="50"
            value={emailaddress}
            onChange={(evnt) => handleChange(index, evnt)}
            name="emailaddress"
            className="form-control"
            id="email_address"
            placeholder="Email Address"
          />
        </td>
        <td>
          <label>Password:</label>
          <input
            maxLength="20"
            type="text"
            value={password}
            onChange={(evnt) => handleChange(index, evnt)}
            name="password"
            className="form-control"
            placeholder="Passwords"
          />
        </td>
        <td  className={userCSS.UserSelect}>
          <label>Role:</label>
          <select
            name="usertype"
            value={usertype}
            type="select"
            onChange={(evnt) => handleChange(index, evnt)}
          >
            <option value=""></option>
            <option id="1">Admin</option>
            <option id="2">Employee</option>
          </select>
        </td>
        <td className={userCSS.UserCancel}>
          <button
            className="btn btn-danger"
            onClick={() => deleteUserRows(index)}
          >
            Cancel
          </button>
        </td>
      </tr>
    );
  });
}

export default Adduser;
