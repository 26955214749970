import React, { useState } from 'react';
import { Table, FormControl, Row } from 'react-bootstrap';
import TableRows from './TableRows';
import addCSS from './Addmenu.module.css';
import axios from 'axios';
import { useEffect } from 'react';
import bgimage from '../../images/backgroundimage.jpg';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout';
import Pagination from '../../components/Pagination/Pagination';
import { addDish, getDishPrice, addDishPrice, getDishPriceById, deleteDish } from '../../services/dishServices'; 
import { getDishBySupplier, getSupplier } from '../../services/supplierServices'; 


const Addmenu = () => {
  const date = new Date().toISOString().slice(0, 10);
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const [supplier, setSupplier] = useState('');
  const [profileImg, setProfileImg] = useState(bgimage);
  const [sup, setSup] = useState([]);
  const [data, setData] = useState([]);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  const totalPage = Math.ceil(data.length / recordsPerPage);

  const handlePageChange = (value) => {
    if (value === '&laquo;' || value === '... ') {
      setCurrentPage(1);
    } else if (value === '&lsaquo;') {
      if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } else if (value === '&rsaquo;') {
      if (currentPage !== totalPage) {
        setCurrentPage(currentPage + 1);
      }
    } else if (value === '&raquo;' || value === ' ...') {
      setCurrentPage(totalPage);
    } else {
      setCurrentPage(value);
    }
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  function refreshsupp() {
    getSupplier().then((response) => setSup(response.data))
  }
  function refreshprice() {
    getDishPrice().then((response) => setData(response.data.sort((x,y) => new Date(y.date) - new Date(x.date))));
  }
  useEffect(() => {
    refreshprice();
    refreshsupp();
  }, []);

  const [dates, setDates] = useState([]);

  const handleAdd = (e) => {
    var imgformData = new FormData();
    let dataArry = []
    e.preventDefault();
    return rowsData.map((data, index) => {
      const { dishName, dishType, supplier, price, image, profileImg } = data;
     
      imgformData.append('dish_name', dishName);
      imgformData.append('dish_type', dishType);
      imgformData.append('supplier', supplier);
      if(image !== '/static/media/backgroundimage.7c9d6d6ee4c74ed7b225.jpg' || image !== ''){
        imgformData.append('file', image);
      }
      
      dataArry.push(dishName)
      dataArry.push(dishType)
      dataArry.push(supplier)
      dataArry.push(price)
      if(image !== '/static/media/backgroundimage.7c9d6d6ee4c74ed7b225.jpg' || image !== ''){
        dataArry.push(image)
      }
     
      for (let i = 0; i < 4; i++){
        if(dataArry[i] === ''){
          return Swal.fire({
                position: 'center',
                icon: 'error',
                text: 'Input field is Required. ',
                showConfirmButton: false,
                showCloseButton: true,
                timer: 2000,
              });
        }
      }

      addDish(imgformData)
        .then((res) => {
          addDishPrice({
            id: null, 
            price: price, 
            is_active: true,
            dish_id: res.data.id
          })
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Submitted',
            text: ' successfully.',
            showConfirmButton: false,
            timer: 1500,
          });
          refreshprice();
          timeout(2500)
          window.location.reload(false)
        })
        .catch(function (error) {
          Swal.fire({
            position: 'center',
            text: 'Error on Adding Dish',
            showConfirmButton: true,
            timer: 2000,
          });
        });
    });
  };

  const [rowsData, setRowsData] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      id: uuidv4(),
      dishName: '',
      dishType: '',
      supplier: '',
      price: '',
      image: '',
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  const handleImage = (id, evnt) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const index = rowsData.findIndex((c) => c.id === id);
        if (index !== -1) {
          const data = rowsData[index];
          setRowsData([
            ...rowsData.slice(0, index),
            { ...data, image: evnt.target.files[0] },
            ...rowsData.slice(index + 1),
          ]);
        }
      }
    };
    reader.readAsDataURL(evnt.target.files[0]);
  };

  const handleDelete = (id, e) => {
    confirmAction(id, e);
  };

  const confirmAction = async (id, e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#76BA1B',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        getDishPriceById(id)
          .then((res) => {
            const dishid = res.data.dish_id.id;
            deleteDish(dishid)
              .then((response) => {
                refreshprice();
              })
              .catch((error) => {
                console.log(error);
              });
          });
        Swal.fire({
          position: 'center',
          title: 'Deleted.',
          text: 'Dish has been deleted.',
          icon: 'success',
          showConfirmButton: false,
          timer: 1100,
        });
        await timeout(500);
        window.location.reload(false);
      }
    });
  };

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [unitPrice, setUnitPrice] = useState(null);
  /**
   * @param id
   * @param currentUnitPrice
   */
  const onEdit = ({ id, currentUnitPrice }) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setUnitPrice(currentUnitPrice);
  };
  /**
   * @param id
   * @param newUnitPrice
   */
  const updateInventory = ({ id, dish_id, newUnitPrice }) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/dish_price/${id}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        id: null,
        price: newUnitPrice,
        is_active: true,
        dish_id: dish_id,
      }),
      
    })
      .then((response) => response.json())
      .then((json) => {
        onCancel();
      });
  };
  /**
   *
   * @param id
   * @param newUnitPrice
   */
  const onSave = ({ id, dish_id, newUnitPrice }) => {
    updateInventory({ id, dish_id, newUnitPrice });
    window.location.reload(true);
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setUnitPrice(null);
  };

  const handleSupplier = (e) => {
    const supplierId = e.target.value;
    getDishBySupplier(supplierId).then((response) => setData(response.data.sort((x,y) => new Date(y.date) - new Date(x.date))))
  }

  return (
    <Layout title={'Add Dish - Admin'}>
      <div className={addCSS.mainContainer}>
        <div className={addCSS.topContent}>
          <h3>Menu Management</h3>
        </div>
        <div className={addCSS.container}>
          <div className={addCSS.firstContent}>
            <div className={addCSS.AddDish_header}>
              <h3>Add Dish</h3>
            </div>
            <div className={addCSS.content}>
              <div className={addCSS.date}>
                <FormControl
                  type="date"
                  value={date}
                  placeholder="Search"
                  onChange={(e) => setDates(e.target.value)}
                  className="me-2"
                />
              </div>
              <button
                className={`btn btn-outline-dark ${
                  rowsData.length === 1 && 'disabled'
                }`}
                onClick={addTableRows}
              >
                Add Dish
              </button>
            </div>
            <div className={addCSS.Add_Dish}>
              <table>
                <tbody>
                  <TableRows
                    rowsData={rowsData}
                    deleteTableRows={deleteTableRows}
                    handleChange={handleChange}
                    handleImage={handleImage}
                    profileimg={profileImg}
                  />
                </tbody>
              </table>
            </div>
            <div className={addCSS.btn1}>
              <button className="btn btn-success" onClick={handleAdd}>
                Submit
              </button>
            </div>
          </div>
          <div className={addCSS.secondContent}>
            <div className={'row ' + addCSS.DishMenuHeader}>
              <div className="col-sm-9 d-flex text-start align-items-center">
                <h3 className={addCSS.DishMenuH3}>Dish Menu</h3>
                
                <select
                  className={addCSS.SelectSupplier}
                  name="dishType"
                  onChange={(e) => handleSupplier(e)}
                  defaultValue={'default'}
                >
                  <option value="default" disabled>Select Supplier</option>
                  {sup.map((item, index) => (
                    <option key={index} value={item.id}>{item.supplier_name}</option>
                  ))}
                </select>  
              </div> 
            </div>

            <div className={addCSS.Addlist_Body}>
              <Table hover>
                <thead>
                  <tr>
                    <th>Dish Name</th>
                    <th>Dish Type</th>
                    <th>Supplier</th>
                    <th>Price</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((dish, index) => (
                    <tr key={index}>
                      <td>{dish.dish_id.dish_name}</td>
                      <td>{dish.dish_id.dish_type === 'Extra' ? 'Extra Order' : dish.dish_id.dish_type}</td>
                      <td>{dish.dish_id.supplier.supplier_name}</td>
                      <td className={addCSS.updatePrice}>
                        {inEditMode.status && inEditMode.rowKey === dish.id ? (
                          <input
                            value={unitPrice}
                            onChange={(event) =>
                              setUnitPrice(event.target.value)
                            }
                          />
                        ) : (
                          `₱${dish.price}`
                        )}
                      </td>
                      <td>{dish.dish_id.created_at.slice(0,10)}</td>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === dish.id ? (
                          <React.Fragment>
                            <button
                              className={'btn btn-success'}
                              onClick={() =>
                                onSave({
                                  id: dish.id,
                                  dish_id: dish.dish_id.id,
                                  newUnitPrice: unitPrice,
                                })
                              }
                            >
                              Save
                            </button>

                            <button
                              className={'btn btn-secondary'}
                              style={{ marginLeft: 8 }}
                              onClick={() => onCancel()}
                            >
                              Cancel
                            </button>
                          </React.Fragment>
                        ) : (
                          <button
                            className={'btn btn-primary'}
                            onClick={() =>
                              onEdit({
                                id: dish.id,
                                currentUnitPrice: dish.price,
                              })
                            }
                          >
                            Edit
                          </button>
                        )}
                        <button
                          className="btn btn-danger"
                          style={{ marginLeft: 8 }}
                          onClick={(e) => handleDelete(dish.id, e)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className={addCSS.pagination}>
                <Pagination
                  currentPage={currentPage}
                  recordsPerPage={recordsPerPage}
                  totalPage={totalPage}
                  siblings={1}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Addmenu;
