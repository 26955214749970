import React from "react";
import "./pagination.css";
import { returnPaginationRange } from "../../utils/paginationUtils";

const Pagination = (props) => {
  let array = returnPaginationRange(
    props.totalPage,
    props.currentPage,
    props.recordsPerPage,
    props.siblings
  );
  return (
    <nav>
      <ul className="pagination pagination-md justify-content-center">
        <li className="pageitem">
          <span
            className="page-link"
            onClick={() => {
              props.onPageChange("&laquo;");
            }}
          >
            &laquo;
          </span>
        </li>
        <li className="pageitem">
          <span
            className="page-link"
            onClick={() => {
              props.onPageChange("&lsaquo;");
            }}
          >
            &lsaquo;
          </span>
        </li>
        {array.map((value) => (
          <li
            key={value}
            className={`page-item ${
              props.currentPage === value ? "active " : " "
            }`}
          >
            <span
              className="page-link"
              onClick={() => {
                props.onPageChange(value);
              }}
            >
              {value}
            </span>
          </li>
        ))}
        <li className="pageitem">
          <span
            className="page-link"
            onClick={() => {
              props.onPageChange("&rsaquo;");
            }}
          >
            &rsaquo;
          </span>
        </li>
        <li className="pageitem">
          <span
            className="page-link"
            onClick={() => {
              props.onPageChange("&raquo;");
            }}
          >
            &raquo;
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
