import React, { useState } from 'react';
import { Table, FormControl, Row } from 'react-bootstrap';
import SuppRows from './SuppRows';
import suppCSS from './Addsupp.module.css';
import axios from 'axios';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout';

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

const Addsupp = () => {
  const handleAdd = (e) => {
    return rowsData.map((data, index) => {
      const { supplier_name, main_dish_free, side_dish_free } = data;
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/supplier/`, {
          id: null,
          supplier_name,
          main_dish_free,
          side_dish_free,
        })
        .then(async (res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: `${res.data.supplier_name}` + ' added.',
            showConfirmButton: false,
            timer: 1100,
          });
          await timeout(500);
          window.location.reload(false);
        })
        .catch((err) => {
          Swal.fire({
            position: 'center',
            text: 'Please fill all forms before submitting.',
            showConfirmButton: false,
            timer: 1500,
          });
        });
    });
  };
  const [rowsData, setRowsData] = useState([]);
  const addSuppRows = () => {
    const rowsInput = {
      supplier_name: '',
      main_dish_free: '',
      side_dish_free: '',
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteSuppRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/supplier/`)
      .then((res) => setData(res.data));
  }, []);

  const handleDelete = (id, e) => {
    confirmAction(id, e);
  };

  const confirmAction = async (id, e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#76BA1B',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/supplier/${id}/`);
        Swal.fire({
          position: 'center',
          title: 'Deleted.',
          text: 'Supplier has been deleted.',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
        await timeout(500);
        window.location.reload(false);
      }
    });
  };

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });

  const [main, setMain] = useState(null);
  /**
   * @param id
   * @param currentMain
   */
  const [side, setSide] = useState(null);
  /**
   * @param id
   * @param currentSide
   */

  const onEdit = ({ id, currentMain, currentSide }) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setMain(currentMain);
    setSide(currentSide);
  };
  /**
   *
   * @param id
   * @param suppName
   * @param newMain
   * @param newSide
   */
  const updateInventory = ({ id, suppName }) => {
    axios.put(`${process.env.REACT_APP_API_ENDPOINT}/supplier/${id}`, {
      id: null,
      supplier_name: suppName,
      main_dish_free: main,
      side_dish_free: side,
    });
  };
  /**
   *
   * @param id
   * @param suppName
   * @param newMain
   * @param newSide
   */
  const onSave = ({ id, suppName }) => {
    updateInventory({ id, suppName });
    window.location.reload(false);
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setMain(null);
    setSide(null);
  };

  return (
    <Layout title={'Add Supplier - Admin'}>
      <div className={suppCSS.mainContainer}>
        <div className={suppCSS.topContent}>
          <h3>Supplier Management</h3>
        </div>
        <div className={suppCSS.container}>
          <div className={suppCSS.firstContent}>
            <div className={suppCSS.Add_Header}>
              <h3>Add Supplier</h3>
              <div className={suppCSS.btn1}>
                <button
                  className={`btn btn-primary ${
                    rowsData.length === 1 && 'disabled'
                  }`}
                  onClick={addSuppRows}
                >
                  Add
                </button>
                <button className="btn btn-success" onClick={handleAdd}>
                  Submit
                </button>
              </div>
            </div>
            <div className={suppCSS.content}>
              <SuppRows
                rowsData={rowsData}
                deleteSuppRows={deleteSuppRows}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className={suppCSS.secondContent}>
            <Table hover>
              <thead>
                <tr>
                  <th>Supplier Name</th>
                  <th>Free Main Dish</th>
                  <th>Free Side Dish</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((supp) => (
                  <tr key={supp.id}>
                    <td className={suppCSS.SuppName}>{supp.supplier_name}</td>
                    <td className={suppCSS.addBool}>
                      {inEditMode.status && inEditMode.rowKey === supp.id ? (
                        <div
                          className={suppCSS.mainFree}
                          onChange={(evnt) => setMain(evnt.target.value)}
                        >
                          
                          <input 
                            type="radio" 
                            id='main_true' 
                            name='main_dish_free' 
                            maxLength="50" 
                            value={true} 
                            defaultChecked={supp.main_dish_free === true} 
                            />
                          <label htmlFor='main_true'>
                            TRUE{' '}
                          </label>
                          <input 
                            type="radio" 
                            id='main_false' 
                            name='main_dish_free' 
                            maxLength="50" value={false} 
                            defaultChecked={supp.main_dish_free === false} />
                          <label htmlFor='main_false'>
                            FALSE{' '}
                          </label>
                        </div>
                      ) : (
                        `${supp.main_dish_free}`
                      )}
                    </td>
                    <td className={suppCSS.addBool}>
                      {inEditMode.status && inEditMode.rowKey === supp.id ? (
                        <div
                          className={suppCSS.sideFree}
                          onChange={(evnt) => setSide(evnt.target.value)}
                        >
                          <input 
                            type="radio" 
                            id='side_true' 
                            maxLength="50" 
                            name='side_dish_free' 
                            value={true} 
                            defaultChecked={supp.side_dish_free === true} />
                          <label htmlFor='side_true'>
                            TRUE{' '}
                          </label>
                          <input 
                            type="radio" 
                            id='side_true' 
                            maxLength="50" 
                            name='side_dish_free' 
                            value={false} 
                            defaultChecked={supp.side_dish_free === false} />
                          <label htmlFor='side_true'>
                            FALSE{' '}
                          </label>
                        </div>
                      ) : (
                        `${supp.side_dish_free}`
                      )}
                    </td>
                    <td>
                      {inEditMode.status && inEditMode.rowKey === supp.id ? (
                        <React.Fragment>
                          <button
                            className={'btn btn-success'}
                            onClick={() =>
                              onSave({
                                id: supp.id,
                                suppName: supp.supplier_name,
                              })
                            }
                          >
                            Save
                          </button>

                          <button
                            className={'btn btn-secondary'}
                            style={{ marginLeft: 8 }}
                            onClick={() => onCancel()}
                          >
                            Cancel
                          </button>
                        </React.Fragment>
                      ) : (
                        <button
                          className={'btn btn-primary'}
                          onClick={() =>
                            onEdit({
                              id: supp.id,
                              currentMain: supp.main_dish_free,
                              currentSide: supp.side_dish_free,
                            })
                          }
                        >
                          Edit
                        </button>
                      )}
                      <button
                        className="btn btn-danger"
                        style={{ marginLeft: 8 }}
                        onClick={(e) => handleDelete(supp.id, e)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Addsupp;
