// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form_formInputs__jJ6U5 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  padding: 3px 20px;\n}\n.Form_formInputs__jJ6U5 span {\n  color: red;\n  font-size: 12px;\n  display: none;\n}\n\ninput:invalid[focused=\"true\"] {\n  border: 1px solid red;\n}\n\ninput:invalid[focused=\"true\"] ~ span {\n  display: block;\n}\n\ninput {\n  display: flex;\n  padding: 10px;\n  width: 300px;\n  border-radius: 3px;\n  border: 1px solid gray;\n}\n\n@media screen and (max-width: 350px) {\n  .Form_formInputs__jJ6U5 {\n    padding: 0;\n  }\n  \n  input{\n    width: auto;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/signin/Form.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,eAAe;EACf,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".formInputs {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  align-items: center;\n  padding: 3px 20px;\n}\n.formInputs span {\n  color: red;\n  font-size: 12px;\n  display: none;\n}\n\ninput:invalid[focused=\"true\"] {\n  border: 1px solid red;\n}\n\ninput:invalid[focused=\"true\"] ~ span {\n  display: block;\n}\n\ninput {\n  display: flex;\n  padding: 10px;\n  width: 300px;\n  border-radius: 3px;\n  border: 1px solid gray;\n}\n\n@media screen and (max-width: 350px) {\n  .formInputs {\n    padding: 0;\n  }\n  \n  input{\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formInputs": "Form_formInputs__jJ6U5"
};
export default ___CSS_LOADER_EXPORT___;
