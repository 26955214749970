import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";

import Adduser from "./Adduser";
import userCSS from "./Usermanagement.module.css";
import Layout from "../../components/Layout";
import Pagination from "../../components/Pagination/Pagination";

const Usermanagement = () => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/user/`;
  const emailurl = `${process.env.REACT_APP_API_ENDPOINT}/email/sendtouser`;
  const [status, setStatus] = useState(null);
  const [addStatus, setAddStatus] = useState(null);
  const [data, setData] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  const totalPage = Math.ceil(data.length / recordsPerPage);
  const numbersPerPage = [...Array(totalPage + 1).keys()].slice(1);

  const handlePageChange = (value) => {
    if (value === "&laquo;" || value === "... ") {
      setCurrentPage(1);
    } else if (value === "&lsaquo;") {
      if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } else if (value === "&rsaquo;") {
      if (currentPage !== totalPage) {
        setCurrentPage(currentPage + 1);
      }
    } else if (value === "&raquo;" || value === " ...") {
      setCurrentPage(totalPage);
    } else {
      setCurrentPage(value);
    }
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleAdd = async (e) => {
    e.preventDefault();

    return rowsData.map(async (data, index) => {
      const { firstname, lastname, emailaddress, password, usertype } = data;
      if (firstname === "" || lastname === "" || emailaddress === ""){
        setAddStatus('Please fill out all fields')
        await timeout(3000)
        setAddStatus("")
        return
      }
      if (password === undefined || password.length <= 6){
        setAddStatus('Password should more than 6 characters')
        await timeout(3000)
        setAddStatus("")
        return
      }
      if (password !== "" && password.length > 6) {
        axios
          .post(url, {
            id: null,
            first_name: firstname,
            last_name: lastname,
            email: emailaddress,
            hashed_password: password,
            role: usertype,
          })
          .then(async function (res) {
            setAddStatus(res.data.message);
            if (res.data.message !== "Email Already Taken") {
              axios
                .post(emailurl, {
                  firstname: firstname,
                  lastname: lastname,
                  email: emailaddress,
                  password: password,
                })
                .then((res) => {
                  refreshuser();
                  alert(`${res.data.message} ${emailaddress}`);
                });
              await timeout(2000);
              setAddStatus("");
              window.location.reload(false);
            }
          })
          .catch(async function (error) {
            if (error.message === "Request failed with status code 422") {
              setAddStatus("Wrong email format");
              await timeout(2000)
              setAddStatus("")
            } else {
              setAddStatus("Please fill out all fields");
              await timeout(2000)
              setAddStatus("")
            }
          });
      } else {
        setAddStatus("Please fill out all fields");
        await timeout(3000)
        setAddStatus("")
      }
    });
  };

  const [rowsData, setRowsData] = useState([]);

  const addTableRows = () => {
    const rowsInput = {
      firstname: "",
      lastname: "",
      emailaddress: "",
      password: "",
      usertype: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteUserRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };
  function refreshuser() {
    axios.get(url).then((res) => setData(res.data));
  }
  // const [data, setData] = useState([]);
  useEffect(() => {
    refreshuser();
  }, []);

  function DeleteUser(index, e) {
    confirmAction(index, e);
  }
  const confirmAction = async (index, e) => {
    let confirmAction = window.confirm("Are you sure?");
    if (confirmAction) {
      axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/user/${index}/`)
        .then(() => {
          setStatus("Deleted successfully!");
          refreshuser();
        });
      await timeout(2000);
      setStatus("");
    } else {
      window.close();
    }
  };

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [password, setPassword] = useState(null);
  /**
   * @param id
   * @param currentPassword
   */
  const onEdit = ({ id, currentPassword }) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setPassword(currentPassword);
  };
  /**
   * @param id
   * @param user_id
   * @param f_name
   * @param l_name
   * @param _email
   * @param _role
   * @param newPassword
   */

  const updateInventory = async ({
    id,
    user_id,
    f_name,
    l_name,
    _email,
    _role,
    newPassword,
  }) => {
    if(newPassword === undefined || newPassword.length <= 6){
      setStatus('Password should more than 6 characters')
      await timeout(2000)
      setStatus("")
      return
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/${id}`, {
      method: "PUT",
      body: JSON.stringify({
        id: user_id,
        first_name: f_name,
        last_name: l_name,
        email: _email,
        role: _role,
        hashed_password: newPassword,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async(data) => {
        setStatus(data.message)
        await timeout(3000)
        setStatus("")
        onCancel();
      });
  };
  /**
   *
   * @param id
   * @param user_id
   * @param f_name
   * @param l_name
   * @param _email
   * @param _role
   * @param newPassword
   */
  const onSave = async ({
    id,
    user_id,
    f_name,
    l_name,
    _email,
    _role,
    newPassword,
  }) => {
    updateInventory({
      id,
      user_id,
      f_name,
      l_name,
      _email,
      _role,
      newPassword,
    });
    
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    setPassword(null);
  };

  return (
    <Layout title={"Usermanagement - Admin"}>
      <div className={userCSS.mainContainer}>
        <div className={userCSS.topContent}>
          <h3>User Management</h3>
        </div>
        <div className={userCSS.container}>
          <div className={userCSS.firstContent}>
            <div className={userCSS.firstContent_Header}>
              <h3>Add User</h3>
              <div className={userCSS.btn1}>
                <button
                  className={`btn btn-primary ${
                    rowsData.length === 1 && "disabled"
                  }`}
                  onClick={addTableRows}
                >
                  Add User
                </button>
                <button className="btn btn-success" onClick={handleAdd}>
                  Submit
                </button>
              </div>
            </div>
            <table className="table">
              <tbody>
                <Adduser
                  rowsData={rowsData}
                  deleteUserRows={deleteUserRows}
                  handleChange={handleChange}
                />
              </tbody>
            </table>
            {addStatus === "User Created Successfully!" ? (
              <span className={userCSS.successMessage}>{addStatus}</span>
            ) : (<span className={userCSS.errorMessage}>{addStatus}</span>)}
          </div>
          <div className={userCSS.secondContent}>
            {status === "Password should more than 6 characters" ? 
              (<span className={userCSS.errorMessage}>{status}</span>) :
              (<span className={userCSS.successMessage}>{status}</span>)}
            <Table hover>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>User Type</th>
                  {inEditMode.status &&  (
                        <th>User New Password</th>
                      )}
                </tr>
              </thead>
              <tbody>
                {records.map((user, index) => (
                  <tr key={index}>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td className={userCSS.updatePrice}>
                      {inEditMode.status && inEditMode.rowKey === user.id ? (
                        <input
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          placeholder="Enter New Password"
                        />
                      ) : (
                        ``
                      )}
                    </td>

                    <td>
                      {inEditMode.status && inEditMode.rowKey === user.id ? (
                        <React.Fragment>
                          <button
                            className={"btn btn-success"}
                            onClick={() =>
                              onSave({
                                id: user.id,
                                user_id: user.id,
                                f_name: user.first_name,
                                l_name: user.last_name,
                                _email: user.email,
                                _role: user.role,
                                newPassword: password,
                              })
                            }
                          >
                            Save
                          </button>

                          <button
                            className={"btn btn-secondary"}
                            style={{ marginLeft: 8 }}
                            onClick={() => onCancel()}
                          >
                            Cancel
                          </button>
                        </React.Fragment>
                      ) : (
                        <button
                          className={"btn btn-primary"}
                          onClick={() =>
                            onEdit({
                              id: user.id,
                              currentUnitPrice: user.price,
                            })
                          }
                        >
                          Reset
                        </button>
                      )}
                      <button
                        className="btn btn-danger"
                        style={{ marginLeft: 8 }}
                        onClick={(e) => DeleteUser(user.id, e)}
                      >
                        x
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
              <div className={userCSS.pagination}>
                <Pagination
                  totalPage={totalPage}
                  currentPage={currentPage}
                  recordsPerPage={recordsPerPage}
                  siblings={1}
                  onPageChange={handlePageChange}
                />
              </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Usermanagement;
