/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import TableContainer from './components/TableContainer';
import TableContainerTwo from './components/TableContainerTwo';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import weeklyCSS from './Weeklyreport.module.css';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import Layout from '../../components/Layout';
import { process, aggregateBy } from "@progress/kendo-data-query";
import { getUserOrderBySupplierAndDate, getUserExtraOrderBySupplierAndDate } from '../../services/orderServices';
import { getSupplier } from '../../services/supplierServices';
import { getOrderSummaryBySupplierAndDateRange, getOrderGrandTotalBySupplierAndDateRange } from '../../services/orderSummaryServices';

const getDefaultDates = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let firstDateOfMonth = new Date(year, month, 1).getDate();

  firstDateOfMonth =
    firstDateOfMonth < 10 ? '0' + firstDateOfMonth : firstDateOfMonth;
  month = month < 10 ? '0' + month : month;

  const startDate = `${year}-${month}-${firstDateOfMonth}`;
  const endDate = date.toISOString().substr(0, 10);

  return {
    startDate,
    endDate,
  };
};

const Weeklyreport = () => {
  const [firstTableDate, setFirstTableDate] = useState([]);
  const [secondTableDate, setSecondTableDate] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [supplierName, setSupplierName] = useState([]);
  const [data, setData] = useState([]);
  const [grandTotal, setGrandTotal] = useState([]);
  const [extra, setExtra] = useState([]);

  const getDefaultDateValues = getDefaultDates();
  const [defaultDates, setDates] = useState(getDefaultDateValues);
  const setDateValues = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDates((prev_state) => ({
      ...prev_state,
      [name]: value,
    }));
  };

  const firstColumns = useMemo(
    () => [
      {
        Header: 'Name',
        title: 'Name',
        accessor: 'order_id.employee_name',
      },
      {
        Header: 'Date',
        title: 'Date',
        accessor: 'order_id.order_date',
      },
    ],
    []
  );

  const secondColumns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Number of Employees',
        accessor: 'number_of_employees',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Total Amount',
        accessor: 'total_amount',
      },
    ],
    []
  );

  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  useEffect(async () => {
    const { data } = await getSupplier();
    if (data) {
      setData(data);
      supplierInfo(data[0].id);
    }
  }, []);

  useEffect(async () => {
    if(supplier.length !== 0){
      const supplierData = data.find(a => a.id === supplier);
      setSupplierName(supplierData.supplier_name)
      handleGo();
    }
  }, [supplier]);
  
  const handleGo = async () => {
    const { data : orders } = await getUserOrderBySupplierAndDate(supplier, defaultDates.startDate, defaultDates.endDate);
    if(orders){
      setFirstTableDate(orders);
    }

    const { data: orderSummaryData } = await getOrderSummaryBySupplierAndDateRange(supplier, defaultDates.startDate, defaultDates.endDate)

    if(orderSummaryData){
      const orderSummary = orderSummaryData.map((a) => {
        return {
          ...a,
          supplier: supplierName, 
          orders: orders.filter((b) => b.order_id.order_date === a.date)
        };
      })
      setSecondTableDate(orderSummary);
    }

    const { data: total } = await getOrderGrandTotalBySupplierAndDateRange(supplier, defaultDates.startDate, defaultDates.endDate)
    setGrandTotal(total[0]);

    const { data: extraOrders } = await getUserExtraOrderBySupplierAndDate(supplier, defaultDates.startDate, defaultDates.endDate);
    setExtra(extraOrders);
  };
 
  const supplierInfo = (supplierId) => {
    setSupplier(supplierId);
    const supplier = data.find(a => a.id === supplierId);
    if(supplier){
      setSupplierName(supplier.supplier_name)
    }
  }

  const summary = [
    {
      field: "total_amount",
      aggregate: "sum",
    },
    {
      field: "number_of_employees",
      aggregate: "sum",
    }
  ];
  const sumOfSummary = aggregateBy(secondTableDate, summary);
  const secondsort = secondTableDate.sort((a, b) =>
    a.date.localeCompare(b.date)
  );

  const totalAmountSumFooter = () => {
    return `P ${sumOfSummary.total_amount.sum.toFixed(2)}`;
  };

  const totalEmployeeSumFooter = () => {
    return sumOfSummary.number_of_employees.sum;
  };

  const grandTotalFooter = () => {
    return 'Grand Total';
  };
  
  const thirdsort = extra.sort((a, b) => b.date.localeCompare(a.date));

  const startDate = new Date(defaultDates.startDate);
  const endDate = new Date(defaultDates.endDate);
  const startMonth = month[startDate.getMonth()];
  const startDay = startDate.getDate();
  const endMonth = month[endDate.getMonth()];
  const endDay = endDate.getDate();
  const startRangeYear = startDate.getFullYear();
  const endRangeYear = endDate.getFullYear();

  let excelReportDateRange = startMonth+' '+startDay+', '+startRangeYear+' - '+endMonth+' '+endDay+', '+endRangeYear;

  const DetailComponent = (props) => {
    const data = props.dataItem.details;
    if (data) {
      return (
        <Grid data={data}>
          <GridColumn field="order_id.employee_name" title="Employee Name" width="120px" />
        </Grid>
      );
    }
    return (
      <div style={{ height: "50px", width: "100%" }}>
        <div style={{ position: "absolute", width: "100%" }}>
          <div className="k-loading-image" />
        </div>
      </div>
    );
  }

  class App extends React.Component {
    _export;
    export = () => {
      const optionsGridOne = this._exportGridOne.workbookOptions();
      const optionsGridTwo = this._exportGridTwo.workbookOptions();
      const optionsGridThree = this._exportGridThree.workbookOptions();
      const optionsGridFour = this._exportGridFour.workbookOptions();
      optionsGridOne.sheets[1] = optionsGridTwo.sheets[0];
      optionsGridOne.sheets[2] = optionsGridThree.sheets[0];
      optionsGridOne.sheets[3] = optionsGridFour.sheets[0];
      optionsGridOne.sheets[0].title = 'Employee Orders Name';
      optionsGridOne.sheets[1].title = 'Accounting Sheet';
      optionsGridOne.sheets[2].title = 'Grand Total';
      optionsGridOne.sheets[3].title = 'Lunch Extra Orders';

      let rows = optionsGridOne.sheets[0].rows;
      //let headerOptions = rows[0].cells[0]; Commented for now, might use this in the future
      let data = secondsort;
      let count = 0;

      for (let idx = data.length - 1; idx >= 0; idx--) {
        let employeeOrderKeys = Object.keys(secondsort[idx].orders)
      
        let employeeOrders = secondsort[idx].orders.filter((a) => 
          a.order_id.order_date === data[idx].date
        );
        for (
          let employeeIdx = employeeOrders.length - 1;
          employeeIdx >= 0;
          employeeIdx--
        ) {
          const employee = employeeOrders[employeeIdx];
          let employeeKeys = parseInt(employeeOrderKeys[employeeIdx]) + 1;

          rows.splice(idx + 5, 0, {
            cells: [
              { value: employeeKeys },
              { value: employee.order_id.employee_name },
            ],
          });
        }
        // Commented for now, might use this in the future
        // rows.splice(idx + 3, 0, {
        //   cells: [
        //     //{},
        //     //Object.assign({}, headerOptions, { value: "Employee Name" }),
        //   ],
        // });
        count++;
      }

      this._exportGridOne.save(
        optionsGridOne,
        optionsGridTwo,
        optionsGridThree
      );
    };
    render() {
      return (
        <div>
          <ExcelExport
            data={secondsort}
            ref={(exporter) => {
              this._exportGridOne = exporter;
            }}
            fileName={`Weekly Report`}
          > 
            <Button
              variant="success"
              title="Export PDF"
              className="k-button k-primary"
              onClick={this.export}
            >
              Export to Excel
            </Button>
            <Grid
              data={secondsort}
              detail={DetailComponent}
              style={{ display: 'none' }}
              resizable={true}
            >
              <GridColumn title={"Supplier: "+ supplierName}> 
                <GridColumn title={"Lunch Orders List. Period: "+ excelReportDateRange} >
                  <GridColumn field="date" title="Date" cellOptions={{background: '#e6e6e6', bold: true}} />
                  <GridColumn
                    field="number_of_employees"
                    title="Number of Employees"
                    width="300px"
                    cellOptions={{background: '#e6e6e6', bold: true}}
                  />
                  <GridColumn field="price" title="Price" cellOptions={{background: '#e6e6e6', bold: true}} />
                  <GridColumn field="total_amount" title="Total Amount" cellOptions={{background: '#e6e6e6', bold: true}} />
                </GridColumn>
              </GridColumn>
            </Grid>
          </ExcelExport>

          <ExcelExport
            data={secondsort}
            ref={(exporter) => {
              this._exportGridTwo = exporter;
            }}
          > 
            <button
              title="Export PDF"
              className="k-button k-primary"
              onClick={this.export}
              style={{ display: 'none' }}
            >
              Export to Excel
            </button>
            <Grid
              data={secondsort}
              style={{ height: '490px', display: 'none' }}
              resizable={true}
            >
              <GridColumn title={"Supplier: "+ supplierName} > 
                <GridColumn title={"Lunch Orders Summary. Period: "+ excelReportDateRange} >
                  <GridColumn field="date" title="Date" footerCellOptions={{bold: true}} footer={grandTotalFooter} />
                  <GridColumn
                    field="number_of_employees"
                    title="Number of Employees"
                    width="300px"
                    footerCellOptions={{bold: true}}
                    footer={totalEmployeeSumFooter}
                  />
                  <GridColumn field="price" title="Price" />
                  <GridColumn field="total_amount" title="Total Amount" footerCellOptions={{bold: true, textAlign: "right"}} footer={totalAmountSumFooter} />
                </GridColumn>
              </GridColumn>
            </Grid>
          </ExcelExport>

          <ExcelExport
            data={[grandTotal]}
            ref={(exporter) => {
              this._exportGridThree = exporter;
            }}
          >
            <button
              title="Export PDF"
              className="k-button k-primary"
              onClick={this.export}
              style={{ display: 'none' }}
            >
              Export to Excel
            </button>
            <Grid
              data={[grandTotal]}
              style={{ height: '490px', display: 'none' }}
              resizable={true}
            >
              <GridColumn
                field="total_employees"
                title="Total Employees"
                width="120px"
              />
              <GridColumn field="price" title="Total Price" />
              <GridColumn field="grand_total" title="Grand Total" />
            </Grid>
          </ExcelExport>
          
          <ExcelExport
            data={thirdsort}
            ref={(exporter) => {
              this._exportGridFour = exporter;
            }}
          > 
           <button
              title="Export PDF"
              className="k-button k-primary"
              onClick={this.export}
              style={{ display: 'none' }}
            >
              Export to Excel
            </button>
            <Grid
              data={thirdsort}
              style={{ height: '490px', display: 'none' }}
              resizable={true}
            >
              <GridColumn field="date" title="Date" width="120px" />
              <GridColumn field="employee" title="Employee" />
              <GridColumn field="order" title="Order" />
              <GridColumn field="quantity" title="Qty" />
              <GridColumn field="unit_price" title="Unit Price" />
              <GridColumn field="total" title="Total" />
            </Grid>
          </ExcelExport>
        </div>
      );
    }
  }

  return (
    <Layout title={'Weekly Report - Admin'}>
      <div className={weeklyCSS.mainContainer}>
        <div className={weeklyCSS.container}>
          <div className={weeklyCSS.topContent}>
            <div className={weeklyCSS.leftContent}>
              <select
                name="supplier"
                value={supplier}
                type="select"
                onChange={(e) => supplierInfo(e.target.value)}
              >
                {data.map((supplier, index) => (
                  <option key={index} value={supplier.id}>
                    {supplier.supplier_name + ''}
                  </option>
                ))}
              </select>
            </div>

            <div className={weeklyCSS.rightContent}>
              <App />
            </div>
          </div>
          <div className={weeklyCSS.mainContent}>
            <div className={weeklyCSS.dateRange}>
              <div className={weeklyCSS.dateStartnEnd}>
                <label>Start Date:</label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  onChange={(e) => setDateValues(e)}
                  value={defaultDates.startDate}
                />
              </div>
              <div className={weeklyCSS.dateStartnEnd}>
                <label>End Date:</label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  onChange={(e) => setDateValues(e)}
                  value={defaultDates.endDate}
                  min={defaultDates.startDate}
                />
              </div>
              <Button
                className="btn btn-info"
                type="button"
                id="clickButton"
                onClick={handleGo}
              >
                Filter <i className="bx bx-filter-alt" />
              </Button>
            </div>
            <div className={weeklyCSS.content}>
              <div className={weeklyCSS.firstContent}>
                <div className={weeklyCSS.firstTable}>
                  <TableContainer
                    columns={firstColumns}
                    data={firstTableDate}
                  />
                </div>
              </div>
              <div className={weeklyCSS.secondContent}>
                <div className={weeklyCSS.secondTable}>
                  <TableContainerTwo
                    columns={secondColumns}
                    data={secondTableDate}
                    grandTotal={grandTotal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Weeklyreport;
