import { Helmet, HelmetProvider } from "react-helmet-async";

const Layout = ({ children, title, description, keywords }) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <title>{title}</title>
        </Helmet>
        <main style={{ minWidth: "100%" }}>{children}</main>
      </HelmetProvider>
    </>
  );
};

Layout.defaultProps = {
  title: "Innovuze Lunch Buddies",
  description: "App created using react and python",
  keywords: "react, python, fastapi, ponyorm",
};

export default Layout;
