import { useEffect, useState } from "react";
import axios from "axios";
import archiveCSS from "./ArchivePage.module.css";
import ListDate from "./components/ListDate";
import Layout from "../../components/Layout";

const Archive_Page = () => {
  const [orders, setOrders] = useState([]);
  const [newDate, setNewDate] = useState([]);

  useEffect(async () => {
    const { data: userOrders } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/ordered_dish/`
    );
    setOrders(userOrders);
  }, []);

  useEffect(() => {
    const ArrangeDate = () => {
      const sortedDate = [...orders].sort((a, b) => {
        const DateA = new Date(a.order_id.order_date);
        const DateB = new Date(b.order_id.order_date);
        return DateA - DateB;
      });
      setNewDate(sortedDate);
    };
    ArrangeDate();
  }, [orders]);

  return (
    <Layout title={"Archive - Admin"}>
      <div className={archiveCSS.MainContainer}>
        <div className={archiveCSS.ArchiveHeader}>
          <h3>Archive Management</h3>
        </div>
        <div className={archiveCSS.Archive_Content}>
          <div className={archiveCSS.Content}>
            <div className={archiveCSS.Content_Header}>
              <h3>Archive List</h3>
              <div className={archiveCSS.Content_Filter}>
                <select name="dishType" type="select">
                  <option hidden>Filter Archive?</option>
                  <option id="1">Week</option>
                  <option id="2">Monthly</option>
                  <option id="3">Yearly</option>
                </select>
              </div>
            </div>
            <div className={archiveCSS.Content_Body}>
              {newDate.map((data) => (
                <ListDate key={data.id} value={data} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Archive_Page;
