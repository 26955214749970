import axios from 'axios';

const getSupplier = () => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/supplier/`)
}

const getDishBySupplier = (supplierId) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dish_menu/supplier/${supplierId}/`)
}

export {
    getSupplier,
	getDishBySupplier
}