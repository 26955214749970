import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Nav, NavDropdown, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import logo from "../../images/logo.png";
import adminCSS from "./Admin.module.css";
import Submenu from "./Submenu";
import Addsupp from "../../pages/Dish/Addsupp";
import Addmenu from "../../pages/Dish/Addmenu";
import Menuday from "../../pages/Dashboard/Dashboard";
import Order from "../../pages/Ordersummary/Ordersummary";
import Weeklyreport from "../../pages/Weeklyreport/Weeklyreport";
import Usermanagement from "../../pages/Usermanagement/Usermanagement";
import { Sidebardata } from "./Sidebardata";
import Cookies from "js-cookie";
import Archive_Page from "../../pages/Archive/ArchivePage";

const Admin = () => {
  const [headername, setHeadername] = useState(
    Cookies.get("lastname") + ", " + Cookies.get("firstname")
  );
  const [showuser, setShowuser] = useState(false);
  const [newPassword, setnewPassword] = useState("");
  const handleCloseuser = () => setShowuser(false);
  const handleShowuser = () => setShowuser(true);
  const handleUpdatepass = async () => {
    axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/user/${Cookies.get("userid")}`,
      {
        first_name: Cookies.get("firstname"),
        last_name: Cookies.get("lastname"),
        email: Cookies.get("email"),
        role: Cookies.get("role"),
        hashed_password: newPassword,
      }
    );
    await timeout(2000);
    signOut();
  };
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const navigate = useNavigate();
  const signOut = () => {
    localStorage.removeItem("temporary");
    localStorage.removeItem("admin");
    navigate("/");
    Cookies.remove("email");
    Cookies.remove("email");
    Cookies.remove("firstname");
    Cookies.remove("role");
    Cookies.remove("lastname");
    Cookies.remove("token");
    Cookies.remove("userid");
  };

  return (
    <div className={adminCSS.mainContainer}>
      <div className={adminCSS.headerContainer}>
        <div className={adminCSS.leftHeader}>
          <img src={logo} alt="img" className={adminCSS.logo} />
          <h5>INNOVUZE SOLUTIONS INC. </h5>
          <h5 id={adminCSS.title}> LUNCH BUDDIES</h5>
        </div>
        <div className={adminCSS.rightHeader}>
          <label>{headername}</label>
          <Nav className={adminCSS.nav}>
            <NavDropdown id="nav-dropdown" title="">
              {/* <NavDropdown.Item href="#action/3.1">
                {headername}
              </NavDropdown.Item> */}

              <NavDropdown.Item
                href="#action/3.2"
                onClick={navigate("/homepage")}
              >
                Homepage
              </NavDropdown.Item>

              <NavDropdown.Divider />
              <NavDropdown.Item onClick={signOut}>
                Log Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </div>
      </div>
      <div className={adminCSS.mainContent}>
        <Modal show={showuser} onHide={handleCloseuser}>
          <Modal.Header closeButton>
            <Modal.Title>{headername}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  <h3>Email address:</h3>
                </Form.Label>
                <br></br>
                <Form.Label>{Cookies.get("email")}</Form.Label>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Change Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={Cookies.get("password")}
                  autoFocus
                  onChange={(e) => setnewPassword(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseuser}>
              Close
            </Button>
            <Button variant="primary" onClick={handleUpdatepass}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <div className={adminCSS.leftContent}>
          {Sidebardata.map((item, key) => {
            return <Submenu item={item} key={key} title={item.title} />;
          })}
        </div>
        <div className={adminCSS.rightContent}>
          <Routes>
            <Route path="/dashboard" element={<Menuday />} />
            <Route path="/addsupplier" element={<Addsupp />} />
            <Route path="/addmenu" element={<Addmenu />} />
            <Route path="/ordersummary" element={<Order />} />
            <Route path="/weeklyreport" element={<Weeklyreport />} />
            <Route path="/usermanagement" element={<Usermanagement />} />
            <Route path="/Archive" element={<Archive_Page />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Admin;
