import suppCSS from "./Supplier.module.css";

function SuppRows({ rowsData, deleteSuppRows, handleChange }) {
  return rowsData.map((data, index) => {
    const { supplier_name } = data;

    return (
      <table>
        <tbody>
          <tr className={suppCSS.MainBody} key={index}>
            <td className={suppCSS.SuppName}>
              {/* <label htmlFor="supplier_name">Supplier Name:</label> */}
              <input
                type="text"
                maxLength="50"
                value={supplier_name}
                onChange={(evnt) => handleChange(index, evnt)}
                name="supplier_name"
                placeholder="Name of the Supplier"
              />
            </td>
            <td className={suppCSS.FreeMenu}>
              <form>
                <label>Free Main Dish :</label>
                <div className={suppCSS.Body_TnF} onChange={(evnt) => handleChange(index, evnt)}>
                  <div className={suppCSS.True}>
                    <input
                      type="radio"
                      maxLength="50"
                      value={true}
                      name="main_dish_free"
                    />
                    TRUE
                  </div>
                  <div className={suppCSS.False}>
                    <input
                      type="radio"
                      maxLength="50"
                      value={false}
                      name="main_dish_free"
                    />
                    FALSE
                  </div>
                </div>
              </form>
            </td>
            <td className={suppCSS.FreeMenu}>
              <form>
                <label>Free Side Dish :</label>
                <div className={suppCSS.Body_TnF} onChange={(evnt) => handleChange(index, evnt)}>
                  <div className={suppCSS.True}>
                    <input
                      type="radio"
                      maxLength="50"
                      value={true}
                      name="side_dish_free"
                    />
                    TRUE
                  </div>
                  <div className={suppCSS.False}>
                    <input
                      type="radio"
                      maxLength="50"
                      value={false}
                      name="side_dish_free"
                    />
                    FALSE
                  </div>
                </div>
              </form>
            </td>
            <td>
              <button
                className="btn btn-danger"
                onClick={() => deleteSuppRows(index)}
              >
                Cancel
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  });
}
export default SuppRows;
