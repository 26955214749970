import { useState,useEffect } from "react";
import './img_holder.css';
import placeHolderImg from '../../images/backgroundimage.jpg';
import { getSupplier } from '../../services/supplierServices';

function TableRows({ rowsData, deleteTableRows, handleChange,handleImage, profileimg}) {
  const [sup, setSup] = useState([]);
  useEffect(() => {
    getSupplier().then((response) => setSup(response.data))
  }, [])

  return rowsData.map((data, index) => {
    const { id,dishName, dishType, supplier, price, image } = data;
    let imgUrl = placeHolderImg;

    if(image){
      imgUrl = URL.createObjectURL(image)
    }

    return (
    <tr className="body" key={index}>
      <td>
        <div className="page">
          <div className="container">
            <div className="img-holder">
              <img src={imgUrl} className="img" alt=""/>
            </div>
            <input type="file" accept="image/*" id="input" name="image" onChange={(evnt) => handleImage(id,evnt)}/>
          </div>
        </div>
      </td>
      <td className="AddDish_Name">
        <input
        type="text"
        maxLength="50"
        value={dishName}
        onChange={(evnt) => handleChange(index, evnt)}
        name="dishName"
        className="input-AddDish"
        placeholder="Name of Dish"
        />
      </td>
      <td className="AddDish_Type">
        <select
        name="dishType"
        value={dishType}
        type="select"
        onChange={(evnt) => handleChange(index, evnt)}
        >
          <option hidden>Choose Dish Type</option>
          <option id="1">Main Dish</option>
          <option id="2">Side Dish</option>
          <option id="3" value={'Extra'}>Extra Order</option>
        </select>
      </td>
      <td className="AddDish_Type">
        <select
          name="supplier"
          value={supplier}
          type="select"
          onChange={(evnt) => handleChange(index, evnt)}
        >
          <option hidden>Choose Supplier</option>
          {sup.map((supplier) => (
            <option value={supplier.id}>{supplier.supplier_name}</option>
          ))}
        </select>
      </td>
      <td className="AddDish_Name">
        <input
          maxLength="10"
          type="number"
          value={price}
          onChange={(evnt) => handleChange(index, evnt)}
          name="price"
          className="input-AddDish"
          placeholder="Price"
        />{" "}
      </td>
      <td className="AddDish_Close">
        <button
          className="btn btn-danger"
          onClick={() => deleteTableRows(index)}
        >
          Cancel
        </button>
      </td>
    </tr>
    );
  });
}
export default TableRows;
