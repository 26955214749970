import axios from 'axios';

const getOrderByDate = (date) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order/search/${date}/`)
}

const getUserOrderBySupplierAndDate = (supplier, startDate, endDate) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order/search/by_supplier/${supplier}/${startDate}/${endDate}/`)
}

const getUserExtraOrderBySupplierAndDate = (supplier, startDate, endDate) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order/search/by_supplier/extra/${supplier}/${startDate}/${endDate}/`)
}



export {
    getOrderByDate,
	getUserOrderBySupplierAndDate,
	getUserExtraOrderBySupplierAndDate
}