import axios from 'axios';

const createDishMenu = (items, menuId) => {
    return axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/dish_menu/`,
        {
        items,
        menu_id: menuId,
        }
    );
}

const getSetDishMenuToday = (dateToday) => {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dish_menu/dish_price_id/${dateToday}/`)
}

const getSetDishSupplier = (supplierId, date) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dish_menu/set/supplier/${supplierId}/${date}/`)
}

const getDishMenuByIdAndDate = (menuId, date) => {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dish_menu/${menuId}/${date}/search/`);
}

const updateDishMenuOfDay = (menuId, value) => {
    axios.put(`${process.env.REACT_APP_API_ENDPOINT}/dish_menu/${menuId}/update/`, {dish_availability: value});
}

export {
    createDishMenu,
    getSetDishMenuToday,
    getSetDishSupplier,
    getDishMenuByIdAndDate,
    updateDishMenuOfDay
}