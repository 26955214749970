// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tableone_header__dX2Qi {\n  width: 500px;\n}\n\n.Tableone_tfoot__O23ST td{\n  font-weight: bold;\n  font-size: 18px;\n  color: #ffff;\n\n}\n\n.Tableone_tfoot__O23ST {\n  background-color: #333;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Weeklyreport/components/Tableone.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,YAAY;;AAEd;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".header {\n  width: 500px;\n}\n\n.tfoot td{\n  font-weight: bold;\n  font-size: 18px;\n  color: #ffff;\n\n}\n\n.tfoot {\n  background-color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Tableone_header__dX2Qi",
	"tfoot": "Tableone_tfoot__O23ST"
};
export default ___CSS_LOADER_EXPORT___;
