import axios from 'axios';

const createMenu = (date) => {
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/menu/`,
    {
      id: null,
      date: date,
    });
}

const getMenuByDate = (date) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/menu/search/${date}/`)
}

const deleteMenuByDate = (date) => {
	return axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/menu/${date}/`)
}

export {
    getMenuByDate,
    deleteMenuByDate,
    createMenu
}