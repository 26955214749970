import { useState } from 'react'
import cardCSS from './ListDate.module.css'

export default function ListDate({value}) {
    const [OpenTable,setOpenTable] =  useState(false);
    const OpenDropDown = () => {
        if (OpenTable === true) return setOpenTable(false);
        setOpenTable(true);
    }
    const Table_Content = (
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Dish Name</th>
                    <th>Dish Type</th>
                    <th>Dish Quantity</th>
                    <th>Supplier</th>
                </tr>
            </thead>
            <tbody>
                <tr key={value.id}>
                    <td>{value.employee_name}</td>
                    <td>{value.dish_name}</td>
                    <td>{value.dish_type}</td>
                    <td>{value.quantity}</td>
                    <td>{value.dish_menu_id.dish_price_id.dish_id.supplier.supplier_name}</td>
                </tr>
            </tbody>
        </table>
    )

    return (
        <div>
            <ul className={cardCSS.UL}>
                <li className={cardCSS.MainBody}>
                    <div className={cardCSS.Header}>
                        <h5>{value.employee_name}</h5>
                        <h5>{value.order_id.order_date}</h5>
                        <button onClick={OpenDropDown}><i className='bx bxs-down-arrow'></i></button>
                    </div>
                    <div className={cardCSS.TableBody}>
                        {OpenTable && Table_Content}
                    </div>
                </li>
            </ul>
        </div>
    )
}