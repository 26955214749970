import _ from "lodash";

export const returnPaginationRange = (
  totalPage,
  currentPage,
  recordsPerPage,
  siblings
) => {
  let totalPageNumberInArray = 7 + siblings;

  if (totalPageNumberInArray >= totalPage) {
    return _.range(1, totalPage + 1);
  }

  let leftSiblingsIndex = Math.max(currentPage - siblings, 1);
  let showLeftDots = leftSiblingsIndex > 2;

  let rightSiblingIndex = Math.min(currentPage + siblings, totalPage);
  let showRightDots = rightSiblingIndex < totalPage - 2;

  if (!showLeftDots && showRightDots) {
    let leftItemsCount = 3 + 2 * siblings;
    let leftRange = _.range(1, leftItemsCount + 1);
    return [...leftRange, " ...", totalPage];
  } else if (showLeftDots && !showRightDots) {
    let rightItemsCount = 3 + 2 * siblings;
    let rightRange = _.range(totalPage - rightItemsCount + 1, totalPage + 1);
    return [1, "... ", ...rightRange];
  } else {
    let middleRange = _.range(leftSiblingsIndex, rightSiblingIndex + 1);
    if(totalPage >= 7){
      return [1, "... ", ...middleRange, " ...", totalPage];
    }
    else{
      return [totalPage];
    }
  }
};
