import axios from 'axios';



const addDish = (dishData) => {
	return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/dish/`, dishData)
}

const deleteDish = (dishId) => {
	return axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/dish/${dishId}/`)
}

const getDishPrice = () => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dish_price/`)
}

const getDishPriceById = (dishId) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dish_price/${dishId}/`)
}

const addDishPrice = (priceData) => {
	return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/dish_price/`, priceData)
}

export {
	addDish,
	deleteDish,
	getDishPrice,
	getDishPriceById,
	addDishPrice
}
