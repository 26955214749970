import { React, useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Nav, NavDropdown } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import maincontentCSS from './Maincontent.module.css';
import logo from '../../images/logo.png';
import Order from './Order';
import { useNavigate } from 'react-router-dom';
import { setAdmin } from '../auth/RequireAuth';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import Layout from '../Layout';
import { getOrderByDate } from '../../services/orderServices'; 

const Maincontent = () => {
  const [cart, showCart] = useState(false);
  const [fname, setFname] = useState(Cookies.get('firstname'));
  const [lname, setLname] = useState(Cookies.get('lastname'));
  const [showuser, setShowuser] = useState(false);
  const [newPassword, setnewPassword] = useState('');
  const handleCloseuser = () => setShowuser(false);
  const handleShowuser = () => setShowuser(true);
  const handleUpdatepass = async () => {
    if (newPassword === undefined || newPassword.length <= 6) {
      Swal.fire({
        position: 'center',
        title: 'Invalid Password',
        icon: 'warning',
        text: 'Password should more than 6 characters',
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      return;
    }
    axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/user/${Cookies.get('userid')}`,
      {
        id: null,
        first_name: Cookies.get('firstname'),
        last_name: Cookies.get('lastname'),
        email: Cookies.get('email'),
        role: Cookies.get('role'),
        hashed_password: newPassword,
      }
    );
    await timeout(500);
    signOut();
  };
  const navigate = useNavigate();
  const signOut = () => {
    localStorage.removeItem('temporary');
    localStorage.removeItem('admin');
    localStorage.removeItem('main_dish');
    localStorage.removeItem('side_dish');
    localStorage.removeItem('extra_order_dish');
    navigate('/');
    Cookies.remove('email');
    Cookies.remove('firstname');
    Cookies.remove('role');
    Cookies.remove('lastname');
    Cookies.remove('token');
    Cookies.remove('userid');
  };

  const Admin = () => {
    navigate('/admin/dashboard');
  };

  const today = new Date();
  const month = today.getMonth() + 1;
  const dateToday =
    today.getFullYear() +
    '-' +
    ('0' + month).slice(-2) +
    '-' +
    ('0' + today.getDate()).slice(-2);

  const [mainDishData, setMainDishData] = useState([]);
  const [sideDishData, setSideDishData] = useState([]);
  const [extraOrderData, setExtraOrderData] = useState([]);
  const [headername, setHeadername] = useState('');
  const [exist, setExist] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);
  const [mainOrder, setMainOrder] = useState([]);
  const [sideOrder, setSideOrder] = useState([]);
  const [extraOrder, setExtraOrder] = useState([]);
  const [dishSupplier, setDishSupplier] = useState([]);
  const [mainRemark, setMainRemark] = useState({
    remarks: '',
  });
  const [sideRemark, setSideRemark] = useState({
    remarks: '',
  });
  const [extraRemark, setExtraRemark] = useState({
    remarks: '',
  });

  const [activeMainDish, setActiveMainDish] = useState(null);
  const [modalMainVisible, setmodalMainVisible] = useState(false);
  const [activeSideDish, setActiveSideDish] = useState(null);
  const [modalSideVisible, setModalSideVisible] = useState(false);
  const [activeExtraOrder, setActiveExtraOrder] = useState(null);
  const [modalExtraOrderVisible, setModalExtraOrderVisible] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [getOrderedDish, setGetOrderedDish] = useState([]);
  const [ws, setWs] = useState(null);
  const [orderDetails, setOrderDetails] = useState([]);
  const [demo, setDemo] = useState([]);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  //getting the websocket data

  useEffect(() => {
    const ws = new WebSocket(
      `${process.env.REACT_APP_WS_ENDPOINT}/websocket/avail/`
    );

    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      setOrderDetails([...orderDetails, ...data]);
    };

    setWs(ws);
  }, [orderDetails]);

  //checking if there's a data in webscocket
  useEffect(() => {
    const mainDishArry = [];
    const sideDishArry = [];
    const extraOrderArry = [];
    const mainInLcalStrage = JSON.parse(localStorage.getItem('main_dish'));
    const sideInLcalStrage = JSON.parse(localStorage.getItem('side_dish'));
    const extraOrderInLcalStrge = JSON.parse(
      localStorage.getItem('extra_order_dish')
    );

    if (mainInLcalStrage === null) {
      return;
    }
    if (sideInLcalStrage === null) {
      return;
    }
    if (extraOrderInLcalStrge === null) {
      return;
    }
    if (mainInLcalStrage.length > 0 && orderDetails.length > 0) {
      mainInLcalStrage.map((mainDish) => {
        const newObj = { ...mainDish };
        orderDetails.filter((data) => {
          if (data.dish_menu_id === mainDish.id) {
            newObj['total_orders'] =
              data && newObj.total_orders - parseInt(data.total_orders);
          }
        });
        mainDishArry.push(newObj);
      });
      setMainDishData(mainDishArry);
    }
    if (sideInLcalStrage.length > 0 && orderDetails.length > 0) {
      sideInLcalStrage.map((sideDish) => {
        const newObj = { ...sideDish };
        orderDetails.filter((data) => {
          if (data.dish_menu_id === sideDish.id) {
            newObj['total_orders'] =
              data && newObj.total_orders - parseInt(data.total_orders);
          }
        });
        sideDishArry.push(newObj);
      });
      setSideDishData(sideDishArry);
    }

    if (extraOrderInLcalStrge.length > 0 && orderDetails.length > 0) {
      extraOrderInLcalStrge.map((extraOrder) => {
        const newObj = { ...extraOrder };
        orderDetails.filter((data) => {
          if (data.dish_menu_id === extraOrder.id) {
            newObj['total_orders'] =
              data && newObj.total_orders - parseInt(data.total_orders);
          }
        });
        extraOrderArry.push(newObj);
      });
      setExtraOrderData(extraOrderArry);
    }
  }, [orderDetails]);

  // getting the ordered dish by userId
  useEffect(() => {
    const fetchOrderedDishByUser = async () => {
      let result = [];
      const { data: order } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/ordered_dish/${Cookies.get(
          'userid'
        )}/${dateToday}/user_order/`
      );
      for (let i = 0; i < order.length; i++) {
        result.push(order[i]);
      }
      setGetOrderedDish(result);
    };

    fetchOrderedDishByUser();
  }, [dateToday]);

  //mainDish
  const refreshmain = async () => {
    const mainDishArry = [];
    await axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/dish_menu/${dateToday}/main_dish`
      )
      .then((res) => {
        const mainDishData = res.data;
        mainDishData.map((mainDish) => {
          const newObj = { ...mainDish };
          newObj['total_orders'] =
            mainDish.dish_availability - mainDish.total_orders;
          mainDishArry.push(newObj);
        });
        localStorage.setItem('main_dish', JSON.stringify(mainDishArry));
        setMainDishData(mainDishArry);
        setDishSupplier(mainDishArry[0].dish_price_id.dish_id.supplier.supplier_name)

        if (fname === undefined) {
          signOut();
        } else if (fname === 'None') {
          signOut();
        } else {
          setHeadername(lname + ', ' + fname);
        }
      });
  };
  //sideDish
  function refreshside() {
    const sideDishArry = [];
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/dish_menu/${dateToday}/side_dish`
      )
      .then((res) => {
        const sideDishData = res.data;
        sideDishData.map((sideDish) => {
          const newObj = { ...sideDish };
          newObj['total_orders'] =
            sideDish.dish_availability - sideDish.total_orders;
          sideDishArry.push(newObj);
        });
        localStorage.setItem('side_dish', JSON.stringify(sideDishArry));
        setSideDishData(sideDishArry);
      });
  }
  //extra order
  function refreshextra() {
    const extraOrderArry = [];
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/dish_menu/${dateToday}/extra`)
      .then((res) => {
        const extraOrder = res.data;
        extraOrder.map((extraDish) => {
          const newObj = { ...extraDish };
          newObj['total_orders'] =
            extraDish.dish_availability - extraDish.total_orders;
          extraOrderArry.push(newObj);
        });
        localStorage.setItem(
          'extra_order_dish',
          JSON.stringify(extraOrderArry)
        );
        setExtraOrderData(extraOrderArry);
      });
  }

  useEffect(() => {
    refreshmain();
    refreshside();
    refreshextra();
    //getting the order_id to set if it exists
    
    /* Commented for now, not used */
    //getOrderByDate(dateToday).then((response) => setExist(response.data))
  }, []);

  useEffect(() => {
    let totalMainDishPrice = mainOrder.reduce((total, c) => {
      const quantity = c.quantity || 1;
      return total + c.dish_price_id.price * quantity;
    }, 0.0);

    if (mainOrder.length !== 0) {
      const mapper = mainOrder.map(
        (c) => c.dish_price_id.dish_id.supplier.main_dish_free
      );
      if (mapper[0] === true) {
        const dish = mainOrder[0];
        totalMainDishPrice -= dish.dish_price_id.price;
      }
    }

    let totalSideDishPrice = sideOrder.reduce((total, c) => {
      const quantity = c.quantity || 1;
      return total + c.dish_price_id.price * quantity;
    }, 0.0);

    if (sideOrder.length !== 0) {
      const mapper = sideOrder.map(
        (c) => c.dish_price_id.dish_id.supplier.side_dish_free
      );
      if (mapper[0] === true) {
        const dish = sideOrder[0];
        totalSideDishPrice -= dish.dish_price_id.price;
      }
    }

    let totalExtraOrderPrice = extraOrder.reduce((total, c) => {
      const quantity = c.quantity || 1;
      return total + c.dish_price_id.price * quantity;
    }, 0.0);

    // if (extraOrder.length) {
    //   const dish = extraOrder[0];
    //   totalExtraOrderPrice -= dish.dish_price_id.price;
    // }

    setTotalPrice(
      totalExtraOrderPrice + totalMainDishPrice + totalSideDishPrice
    );
  }, [extraOrder, mainOrder, sideOrder]);

  //Modal for Main Dish
  const handleMainChange = (e) => {
    setMainRemark({ remarks: e.target.value });
  };

  const handleMainDish = (itemMain) => {
    setmodalMainVisible(true);
    setActiveMainDish(itemMain);
  };

  const handleMainSubmit = () => {
    const free = mainOrder.findIndex((c) => c.id === activeMainDish.id);
    const result = {
      ...activeMainDish,
      mainRemark,
      uniqueId: uuidv4(),
      free: free === -1 ? 1 : 0,
    };
    setMainOrder([...mainOrder, result]);
    setMainRemark('');
    setmodalMainVisible(false);
  };

  //Modal for Side Dish
  const handleSideChange = (e) => {
    setSideRemark({ remarks: e.target.value });
  };

  const handleSideDish = (itemSide) => {
    setModalSideVisible(true);
    setActiveSideDish(itemSide);
  };

  const handleSideSubmit = () => {
    const free = sideOrder.findIndex((c) => c.id === activeSideDish.id);
    const result = {
      ...activeSideDish,
      sideRemark,
      uniqueId: uuidv4(),
      free: free === -1 ? 1 : 0,
    };

    setSideOrder([...sideOrder, result]);
    setSideRemark('');
    setModalSideVisible(false);
  };

  //Modal for Extra Order
  const handleExtraChange = (e) => {
    setExtraRemark({ remarks: e.target.value });
  };

  const handleExtraOrder = (itemExtraOrder) => {
    setModalExtraOrderVisible(true);
    setActiveExtraOrder(itemExtraOrder);
  };

  const handleExtraOrderSubmit = () => {
    const result = {
      ...activeExtraOrder,
      extraRemark,
      uniqueId: uuidv4(),
    };

    setExtraOrder([...extraOrder, result]);
    setExtraRemark({
      remarks: '',
    });
    setModalExtraOrderVisible(false);
  };

  // updating quantities on mainOrder
  const updateMainQuantity = (id, quantity) => {
    const index = mainOrder.findIndex((c) => c.uniqueId === id);
    if (index !== -1) {
      const order = mainOrder[index];
      setMainOrder([
        ...mainOrder.slice(0, index),
        {
          ...order,
          quantity: Number(order.quantity || 1) + quantity,
        },
        ...mainOrder.slice(index + 1),
      ]);
    }
  };

  const handleMainIncrement = (id) => {
    updateMainQuantity(id, 1);
    if (updateMainQuantity <= 1) {
      return;
    } else {
      setTotal(total + 62);
    }
    setQuantity(quantity + quantity);
  };

  const handleMainDecrement = (id) => {
    updateMainQuantity(id, -1);
    if (total === 0) {
      return;
    } else {
      setTotal(total - 62);
    }
  };
  // updating quantities on sideOrder
  const updateSideQuantity = (id, quantity) => {
    const index = sideOrder.findIndex((c) => c.uniqueId === id);
    if (index !== -1) {
      const order = sideOrder[index];
      setSideOrder([
        ...sideOrder.slice(0, index),
        {
          ...order,
          quantity: Number(order.quantity || 1) + quantity,
        },
        ...sideOrder.slice(index + 1),
      ]);
    }
  };

  const handleSideIncrement = (id) => {
    updateSideQuantity(id, 1);
    if (updateSideQuantity <= 1) {
      return;
    } else {
      setTotal(total + 15);
    }
  };

  const handleSideDecrement = (id) => {
    updateSideQuantity(id, -1);
    if (total === 0) {
      return;
    } else {
      setTotal(total - 15);
    }
  };

  // updating quantities on extraOrder
  const updateExtraOrderQuantity = (id, quantity) => {
    const index = extraOrder.findIndex((c) => c.uniqueId === id);
    if (index !== -1) {
      const order = extraOrder[index];
      setExtraOrder([
        ...extraOrder.slice(0, index),
        {
          ...order,
          quantity: Number(order.quantity || 1) + quantity,
        },
        ...extraOrder.slice(index + 1),
      ]);
    }
  };

  const handleExtraOrderIncrement = (id) => {
    updateExtraOrderQuantity(id, 1);
    if (updateExtraOrderQuantity <= 1) {
      return;
    } else {
      setTotal(total + 10);
    }
  };

  const handleExtraOrderDecrement = (id) => {
    updateExtraOrderQuantity(id, -1);
    if (total === 0) {
      return;
    } else {
      setTotal(total - 10);
    }
  };

  // Post Final Order
  const HandleOrder = async () => {
    // const orderDetails = JSON.parse(localStorage.getItem('order_details'))
    const orders = [...mainDishData, ...sideDishData, ...extraOrderData];
    const extra = extraOrder.filter(
      (c) => c.dish_price_id.dish_id.dish_type === 'Extra'
    );
    const mustOrder = [...mainOrder, ...sideOrder];
    const newOrder = [...mainOrder, ...sideOrder, ...extra];
    const orderData = [newOrder, dateToday];

    Swal.fire({
      title: 'Continue?',
      text: '',
      icon: 'warning',
      html: "<span style='color:red; font-size:16px; font-weight:bold'>**NO CANCELLATION OF ORDER ONCE CONFIRMED.**</span>",
      showCancelButton: true,
      confirmButtonColor: '#76BA1B',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (mustOrder.length === 0) {
          Swal.fire({
            position: 'center',
            icon: 'warning',
            text: 'Please select main/side dish.',
            showConfirmButton: false,
            timer: 1000,
          });
        } else if (mustOrder.length >= 1) {
          const mapper = orderData[0].map(
            ({
              id,
              dish_availability,
              dish_price_id,
              menu_id,
              mainRemark,
              quantity,
              sideRemark,
              extraRemark,
            }) => ({
              id: id,
              dish_availability: dish_availability,
              dish_price_id: dish_price_id,
              menu_id: menu_id,
              remarks: mainRemark || sideRemark || extraRemark,
              quantity: quantity,
            })
          );

          const items = mapper.map(
            ({ id, quantity, remarks, dish_price_id, dish_availability }) => ({
              dish_menu_id: id,
              quantity: quantity || 1,
              remarks: remarks?.remarks || '',
              order_price: dish_price_id.price,
              dish_availability: dish_availability,
            })
          );
          let results = [];
          for (var i = 0; i < items.length; i++) {
            for (var j = 0; j < orders.length; j++) {
              if (items[i].dish_menu_id === orders[j].id) {
                const updateTotalQuantity = orders[j].total_orders;
                if (updateTotalQuantity < items[i].quantity) {
                  results.push(items[i]);
                }
              }
            }
          }
          if (results.length === 0) {
            const { data: finalOrderData } = await axios
              .post(`${process.env.REACT_APP_API_ENDPOINT}/order/`, {
                id: null,
                order_date: dateToday,
                user_id: Cookies.get('userid'),
              })
              .catch(function (error) {
                if (
                  (error.message === 'Request failed with status code 400') &
                  (error.response.data.message ===
                    'User already exist on that order date')
                ) {
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: 'You can only order once per day.',
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }
                if (
                  (error.message === 'Request failed with status code 400') &
                  (error.response.data.message === "It's not time to order yet")
                ) {
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: "It's not time to order yet!",
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }
                if (
                  (error.message === 'Request failed with status code 400') &
                  (error.response.data.message === 'Order time is already Over')
                ) {
                  Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    text: 'Order time is already Over!',
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }
              });

            const data = await axios.post(
              `${process.env.REACT_APP_API_ENDPOINT}/ordered_dish/`,
              {
                order_id: finalOrderData.id,
                items,
              }
            );
            Swal.fire({
              position: 'center',
              title: 'Ordered',
              text: 'Submitted successfully.',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            });
            await timeout(500);
            // window.location.reload(false);

            const id_avail = mapper.map((c) => c.id);
            const quantity = items.map((c) => c.quantity);
            ws.send([id_avail]);
            ws.send([quantity]);
            // timeout(2000);
            // refreshmain();
            // refreshside();
            // refreshextra();
            window.location.reload();
          } else {
            // results = [];
            Swal.fire({
              position: 'center',
              title: 'Sold Out',
              icon: 'warning',
              text: 'One of the dish is sold out or the quantity is greater than availability please pick again.',
              showConfirmButton: false,
              showCloseButton: true,
            });
          }
        }
      }
    });
  };

  const deleteTableMainRow = (rowMainId) => {
    const rowMain = [...mainOrder];
    const index = mainOrder.findIndex((c) => c.id === rowMainId);
    rowMain.splice(index, 1);
    setMainOrder(rowMain);
  };

  const deleteTableSideRow = (rowSideId) => {
    const rowSide = [...sideOrder];
    const index = sideOrder.findIndex((c) => c.id === rowSideId);
    rowSide.splice(index, 1);
    setSideOrder(rowSide);
  };

  const deleteTableExtraOrderRow = (rowExtraOrderId) => {
    const rowExtraOrder = [...extraOrder];
    const index = extraOrder.findIndex((c) => c.id === rowExtraOrderId);
    rowExtraOrder.splice(index, 1);
    setExtraOrder(rowExtraOrder);
  };

  const totalItemsOnCart = () => {
    let totalItems = 0;

    if(mainOrder.length){
      totalItems += mainOrder.length;
    }
    if(sideOrder.length){
      totalItems += sideOrder.length;
    }
    if(extraOrder.length){
      totalItems += extraOrder.length;
    }

    return totalItems;
  }

  const handleCartModal = (show) => {
    showCart(show);
  }

  return (
    <Layout title={'Homepage - Innovuze Lunch Buddies'}>
      <div className={maincontentCSS.mainContent}>
        <Order
          show={cart}
          handleCartModal={handleCartModal}
          mainOrder={mainOrder}
          sideOrder={sideOrder}
          deleteTableMainRow={deleteTableMainRow}
          deleteTableSideRow={deleteTableSideRow}
          deleteTableExtraOrderRow={deleteTableExtraOrderRow}
          mainRemark={mainRemark}
          handleMainDecrement={handleMainDecrement}
          handleMainIncrement={handleMainIncrement}
          handleSideDecrement={handleSideDecrement}
          handleSideIncrement={handleSideIncrement}
          extraOrder={extraOrder}
          handleExtraOrderDecrement={handleExtraOrderDecrement}
          handleExtraOrderIncrement={handleExtraOrderIncrement}
          total={totalPrice}
          HandleOrder={HandleOrder}
          getOrderedDish={getOrderedDish}
        />
        <div className={maincontentCSS.topBarContainer}>
          <div className={maincontentCSS.leftBar}>
            <img src={logo} alt='img' className={maincontentCSS.logo} />
            <h5>INNOVUZE SOLUTIONS INC.</h5>
            <h5 id={maincontentCSS.title}>LUNCH BUDDIES</h5>
          </div>
          <div className={maincontentCSS.rightBar}>
            <label>{headername}</label>
            <Nav className={maincontentCSS.nav}>
              <NavDropdown
                id='nav-dropdown-dark-example'
                title=''
                color='white'
              >
                {Cookies.get('role') === 'Admin' ? (
                  <NavDropdown.Item onClick={Admin}>Admin</NavDropdown.Item>
                ) : null}
                <NavDropdown.Item
                  onClick={() => {
                    setShowuser(true);
                  }}
                >
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={signOut}>Log Out</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <i className='bx bx-cart position-relative' onClick={() => handleCartModal(true)}>
              <span className={'position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ' + maincontentCSS.customBadge} style={{ 'display' : totalItemsOnCart() === 0 ? 'none' : '' }}>
                {totalItemsOnCart() === 0 ? null : totalItemsOnCart()}
              </span>
            </i>
          </div>
        </div>
        <div className={maincontentCSS.headerContent}>
          <div className={maincontentCSS.headerData}>
            <Modal show={showuser} onHide={handleCloseuser}>
              <Modal.Header closeButton>
                <Modal.Title>{headername}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label>
                      <h3>Email address:</h3>
                    </Form.Label>
                    <br></br>
                    <Form.Label>{Cookies.get('email')}</Form.Label>
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlTextarea1'
                  >
                    <Form.Label>Change Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder={Cookies.get('password')}
                      autoFocus
                      onChange={(e) => setnewPassword(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleCloseuser}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleUpdatepass}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            <span style={{ color: 'white' }}>MENU FOR TODAY!!</span>
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-center align-items-center"><h5 className="fw-medium">Supplier: </h5> <h4 className="fw-bold ms-2">{ dishSupplier }</h4></div>
        <div className={maincontentCSS.Content}>
         
          <div className={maincontentCSS.topContent}>
            <span>Main Dish</span>
            {/* <input
            type="date"
            value={dateToday}
            className={maincontentCSS.menuDate}
          /> */}
          </div>
          <div className={maincontentCSS.wrapperContent}>
            <ul className={maincontentCSS.dish_list}>
              <Modal
                show={modalMainVisible}
                dialogClassName={maincontentCSS.modalDialog}
              >
                <div className={maincontentCSS.modalHeader}>
                  {activeMainDish ? (
                    <img
                      src={activeMainDish.dish_price_id.dish_id.img_url}
                      className={maincontentCSS.modalHeader}
                      alt='main-dish'
                    />
                  ) : null}
                </div>
                <Modal.Header>
                  <div className={maincontentCSS.leftheader}>
                    {activeMainDish ? (
                      <h3>{activeMainDish.dish_price_id.dish_id.dish_name}</h3>
                    ) : null}
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className={maincontentCSS.modalContainer}>
                    <br />
                    <div>
                      <h5>Special Instructions</h5>
                      <p>Any specific preferences? Let the supplier know.</p>
                      {activeMainDish ? (
                        <Form.Control
                          as='textarea'
                          placeholder='Enter comment...'
                          rows={3}
                          value={mainRemark.remarks}
                          onChange={handleMainChange}
                        />
                      ) : null}
                    </div>
                    <br />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      setmodalMainVisible(false);
                      setMainRemark({
                        remarks: '',
                      });
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant='primary'
                    onClick={handleMainSubmit}
                    disabled={mainOrder.some((c) => c.id === activeMainDish.id)}
                  >
                    Add Dish
                  </Button>
                </Modal.Footer>
              </Modal>
              {mainDishData.map((itemMain, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleMainDish(itemMain)}
                    type='submit'
                    className={maincontentCSS.btn}
                    disabled={itemMain.total_orders === 0  || getOrderedDish.length !== 0}
                  >
                    <div className={maincontentCSS.dish_container}>
                      <picture>
                        <div className={maincontentCSS.dish_picture}>
                          <img
                            className={maincontentCSS.img_index}
                            src={itemMain.dish_price_id.dish_id.img_url}
                            alt='dish'
                          />
                        </div>
                      </picture>
                      <div className={maincontentCSS.dish_btn}>
                        {getOrderedDish.length === 0 ? (
                          <div className={maincontentCSS.dish_text}>+</div>
                        ) : null }
                      </div>
                      <figcaption className={'d-flex align-items-center ' + maincontentCSS.dish_info}>
                        <span className={maincontentCSS.dish_avail}>
                          {itemMain.total_orders === 0 ? (
                            <span className={maincontentCSS.notAvailable}>
                              Not Available
                            </span>
                          ) : (
                            <span>
                              Availability: {itemMain.total_orders}/
                              {itemMain.dish_availability}
                            </span>
                          )}
                        </span>
                        <span className={maincontentCSS.dish_name +' '+ maincontentCSS.smallDishName}>
                          {itemMain.dish_price_id.dish_id.dish_name}
                        </span>
                      </figcaption>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={sideDishData.length === 0 ? 'd-none' : maincontentCSS.Content}>
          <div className={maincontentCSS.topContent}>
            <span>Side Dish</span>
          </div>
          <div className={maincontentCSS.wrapperContent}>
            <ul className={maincontentCSS.dish_list}>
              <Modal
                show={modalSideVisible}
                dialogClassName={maincontentCSS.modalDialog}
              >
                <div className={maincontentCSS.modalHeader}>
                  {activeSideDish ? (
                    <img
                      src={activeSideDish.dish_price_id.dish_id.img_url}
                      className={maincontentCSS.modalHeader}
                      alt='side-dish'
                    />
                  ) : null}
                </div>
                <Modal.Header>
                  <div className={maincontentCSS.header}>
                    <div className={maincontentCSS.leftheader}>
                      {activeSideDish ? (
                        <h3>
                          {activeSideDish.dish_price_id.dish_id.dish_name}
                        </h3>
                      ) : null}
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <br />
                  <div>
                    <h5>Special Instructions</h5>
                    <p>Any specific preferences? Let the supplier know.</p>
                    <Form.Control
                      as='textarea'
                      placeholder='Enter comment...'
                      rows={3}
                      value={sideRemark.remarks}
                      onChange={handleSideChange}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      setModalSideVisible(false);
                      setSideRemark({
                        remarks: '',
                      });
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant='primary'
                    onClick={handleSideSubmit}
                    disabled={sideOrder.some((c) => c.id === activeSideDish.id)}
                  >
                    Add Dish
                  </Button>
                </Modal.Footer>
              </Modal>
              {sideDishData.map((itemSide, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleSideDish(itemSide)}
                    type='submit'
                    className={maincontentCSS.btn}
                    disabled={itemSide.total_orders === 0 || getOrderedDish.length !== 0}
                  >
                    <div className={maincontentCSS.dish_container}>
                      <picture>
                        <div className={maincontentCSS.dish_picture}>
                          <img
                            className={maincontentCSS.img_index}
                            src={itemSide.dish_price_id.dish_id.img_url}
                            alt='side-dish-img'
                          />
                        </div>
                      </picture>
                      <div className={maincontentCSS.dish_btn}>
                        {getOrderedDish.length === 0 ? (
                          <div className={maincontentCSS.dish_text}>+</div>
                        ) : null }
                      </div>
                      <figcaption className={'d-flex align-items-center '+maincontentCSS.dish_info}>
                        <span className={maincontentCSS.dish_avail}>
                          {itemSide.total_orders === 0 ? (
                            <span className={maincontentCSS.notAvailable}>
                              Not Available
                            </span>
                          ) : (
                            <span>
                              Availability: {itemSide.total_orders}/
                              {itemSide.dish_availability}
                            </span>
                          )}
                        </span>
                        <span className={maincontentCSS.dish_name +' '+maincontentCSS.smallDishName}>
                          {itemSide.dish_price_id.dish_id.dish_name}
                        </span>
                      </figcaption>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={maincontentCSS.Content}>
          <div className={maincontentCSS.topContent}>
            <span>Extra Order</span>
          </div>
          <div className={maincontentCSS.wrapperContent}>
            <ul className={maincontentCSS.dish_list}>
              <Modal
                show={modalExtraOrderVisible}
                dialogClassName={maincontentCSS.modalDialog}
              >
                <div className={maincontentCSS.modalHeader}>
                  {activeExtraOrder ? (
                    <img
                      src={activeExtraOrder.dish_price_id.dish_id.img_url}
                      className={maincontentCSS.modalHeader}
                      alt='extra-order'
                    />
                  ) : null}
                </div>
                <Modal.Header>
                  <div className={maincontentCSS.header}>
                    <div className={maincontentCSS.leftheader}>
                      {activeExtraOrder ? (
                        <h3>
                          {activeExtraOrder.dish_price_id.dish_id.dish_name}
                        </h3>
                      ) : null}
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <br />
                  <div>
                    <h5>Special Instructions</h5>
                    <p>Any specific preferences? Let the supplier know.</p>
                    <Form.Control
                      as='textarea'
                      placeholder='Enter comment...'
                      rows={3}
                      value={extraRemark.remarks}
                      onChange={handleExtraChange}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      setModalExtraOrderVisible(false);
                      setExtraRemark({
                        remarks: '',
                      });
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant='primary'
                    onClick={handleExtraOrderSubmit}
                    disabled={extraOrder.some(
                      (c) => c.id === activeExtraOrder.id
                    )}
                  >
                    Add Dish
                  </Button>
                </Modal.Footer>
              </Modal>
              {extraOrderData.map((itemExtraOrder, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleExtraOrder(itemExtraOrder)}
                    type='submit'
                    className={maincontentCSS.btn}
                    disabled={itemExtraOrder.total_orders === 0  || getOrderedDish.length !== 0}
                  >
                    <div className={maincontentCSS.dish_container}>
                      <picture>
                        <div className={maincontentCSS.dish_picture}>
                          <img
                            className={maincontentCSS.img_index}
                            src={itemExtraOrder.dish_price_id.dish_id.img_url}
                            alt='side-dish-img'
                          />
                        </div>
                      </picture>
                      <div className={maincontentCSS.dish_btn}>
                        {getOrderedDish.length === 0 ? (
                          <div className={maincontentCSS.dish_text}>+</div>
                        ) : null }
                      </div>
                      <figcaption className={'d-flex align-items-center '+ maincontentCSS.dish_info}>
                        <span className={maincontentCSS.dish_avail}>
                          {itemExtraOrder.total_orders === 0 ? (
                            <span className={maincontentCSS.notAvailable}>
                              Not Available
                            </span>
                          ) : (
                            <>
                            <span>
                              Availability: {itemExtraOrder.total_orders}/
                              {itemExtraOrder.dish_availability}
                            </span>
                            <span className="ms-5">
                              Price: &#8369; {itemExtraOrder.dish_price_id.price}
                            </span>
                           </>
                          )}
                        </span>
                        <span className={maincontentCSS.dish_name +' '+ maincontentCSS.smallDishName}>
                          {itemExtraOrder.dish_price_id.dish_id.dish_name}
                        </span>
                      </figcaption>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Maincontent;
