import axios from 'axios';

const getOrderSummaryBySupplierAndDateRange = (supplierId, startDate, endDate) => {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order_summary/search/by_supplier/${supplierId}/${startDate}/${endDate}/`)
}

const getOrderGrandTotalBySupplierAndDateRange = (supplier, startDate, endDate) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order_summary/grand_total/by_supplier/${supplier}/${startDate}/${endDate}/`);
}

const getAllDishOrderByDate = (date) => {
	return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order_summary/${date}`);
}

const updateOrderSummaryByID = (orderSummaryId, data) => {
	return axios.put(`${process.env.REACT_APP_API_ENDPOINT}/order_summary/${orderSummaryId}/`,
        {
          number_of_employees: data.number_of_employees,
          date: data.date,
          price: data.price,
          total_amount: data.amount,
          supplier: data.supplier
        }
      );
}

export {
    getOrderSummaryBySupplierAndDateRange,
    getOrderGrandTotalBySupplierAndDateRange,
    getAllDishOrderByDate,
    updateOrderSummaryByID
}