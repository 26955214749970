import React, { useState } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';

import './Order.css';

const Order = ({
  show,
  handleCartModal,
  mainOrder,
  sideOrder,
  deleteTableMainRow,
  deleteTableSideRow,
  deleteTableExtraOrderRow,
  handleMainIncrement,
  handleMainDecrement,
  handleSideIncrement,
  handleSideDecrement,
  extraOrder,
  handleExtraOrderIncrement,
  handleExtraOrderDecrement,
  total,
  HandleOrder,
  getOrderedDish,
}) => {
  const [showModal, setShowModal] = useState(false);

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#76BA1B',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_API_ENDPOINT}/ordered_dish/${id[0]}/`
          )
          .then((response) => {

          })
          .catch((error) => {
            if (
              (error.message === 'Request failed with status code 400') &
              (error.response.data.message ===
                'Time is already up to delete order')
            ) {
              Swal.fire({
                position: 'center',
                icon: 'warning',
                text: 'Cannot Delete Orders.',
                showConfirmButton: false,
                timer: 1000,
              });
            }
          });
        Swal.fire({
          position: 'center',
          title: 'Deleted.',
          text: 'Dish has been deleted.',
          icon: 'success',
          showConfirmButton: false,
          timer: 1100,
        });
        await timeout(500);
        window.location.reload(false);
      }
    });
  };
  
  return (
    <div className={show ? 'modalContainer active' : 'modalContainer '}>
      <div className='modaltopContent'>
        <Container>
          <Row>
            <Col className='text-start' sm={12}>
              <div className="d-flex align-items-center">
                <span className='me-1'>Your Cart </span>
            
                {getOrderedDish.length !== 0 && (
                  <>
                    -
                    <Button type='submit' className='btn d-flex align-items-center ms-2 customBtnOrdered' onClick={handleOpenModal}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" fill="currentColor" className="bi bi-clipboard-check-fill me-2" viewBox="0 0 16 16">
                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z"/>
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
                      </svg>
                      Ordered
                    </Button>
                  </>
                )}

                <button type='button' className='btn-close customBtnClose align-middle ms-auto' aria-label='Close' onClick={() => handleCartModal(false)}></button>
              </div>
            </Col>
            {/* <Col sm={2}>
              <button type='button' className='btn-close customBtnClose align-middle' aria-label='Close' onClick={() => handleCartModal(false)}></button>
            </Col> */}
          </Row>
       </Container>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <h2>Ordered Dish</h2>
        </Modal.Header>
        <Modal.Body>
          <MDBTable>
            <MDBTableHead>
              <tr>
                <th rowSpan='2' scope='col'>
                  Order
                </th>
                <th rowSpan='2' scope='col'>
                  Quantity
                </th>
                <th rowSpan='2' scope='col'>
                  Price
                </th>
                <th rowSpan='2' scope='col' colSpan='3'>
                  Remarks
                </th>
                <th rowSpan='2' scope='col'></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody className='tableBody'>
              {getOrderedDish.map((orderedDish, index) => (
                <tr key={index}>
                  <td>
                    {orderedDish.dish_menu_id.dish_price_id.dish_id.dish_name}
                  </td>
                  <td>{orderedDish.quantity}</td>
                  <td>₱{orderedDish.dish_menu_id.dish_price_id.price}</td>
                  <td colSpan='3' className='remarks'>
                    <span>{orderedDish?.mainRemark?.remarks}</span>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant='danger'
            onClick={() => {
              handleDelete(getOrderedDish.map((d) => d.order_id.id));
            }}
          >
            Delete Orders
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='modalContent'>
        <hr />
        <MDBTable>
          <MDBTableHead>
            <tr>
              <th rowSpan='2' scope='col'>
                Order
              </th>
              <th rowSpan='2' scope='col'>
                Quantity
              </th>
              <th rowSpan='2' scope='col'>
                Price
              </th>
              <th rowSpan='2' scope='col' colSpan='3'>
                Remarks
              </th>
              <th rowSpan='2' scope='col'></th>
            </tr>
          </MDBTableHead>
          <MDBTableBody className='tableBody'>
            {mainOrder.map((mainOrders, index) => (
              <tr key={index}>
                <td>{mainOrders.dish_price_id.dish_id.dish_name}</td>
                <td>
                  <Button
                    variant='success'
                    size='sm'
                    onClick={() => handleMainDecrement(mainOrders.uniqueId)}
                    disabled={mainOrders.quantity <= 1}
                  >
                    -
                  </Button>
                  <span className='span'>{mainOrders.quantity || 1}</span>
                  <Button
                    variant='success'
                    size='sm'
                    onClick={() => handleMainIncrement(mainOrders.uniqueId)}
                  >
                    +
                  </Button>
                </td>
                {(mainOrders.quantity === undefined ||
                  mainOrders.quantity === 1) &&
                  index === 0 ? (
                  <td value="120">Free</td>
                ) : (
                  <td>₱{mainOrders.dish_price_id.price}</td>
                )}
                <td colSpan='3' className='remarks'>
                  <span>{mainOrders.mainRemark.remarks}</span>
                </td>
                <td>
                  <span
                    className='spanButton'
                    onClick={() => deleteTableMainRow(mainOrders.id)}
                  >
                    <i className='bx bx-trash' />
                  </span>
                </td>
              </tr>
            ))}
          </MDBTableBody>
          <MDBTableBody className='tableBody'>
            {sideOrder.map((sideOrders, index) => (
              <tr key={index}>
                <td>{sideOrders.dish_price_id.dish_id.dish_name}</td>
                <td>
                  <Button
                    variant='success'
                    size='sm'
                    onClick={() => handleSideDecrement(sideOrders.uniqueId)}
                    disabled={sideOrders.quantity <= 1}
                  >
                    -
                  </Button>
                  <span className='span'>{sideOrders.quantity || 1}</span>
                  <Button
                    variant='success'
                    size='sm'
                    onClick={() => handleSideIncrement(sideOrders.uniqueId)}
                  >
                    +
                  </Button>
                </td>
                <td>
                  {(sideOrders.quantity === undefined ||
                    sideOrders.quantity === 1) &&
                    index === 0 ? (
                    'Free'
                  ) : (
                    '₱'+sideOrders.dish_price_id.price
                  )}
                </td>
                <td colSpan="3" className="remarks">
                  <span>{sideOrders.sideRemark.remarks}</span>
                </td>
                <td>
                  <span
                    className='spanButton'
                    onClick={() => deleteTableSideRow(sideOrders.id)}
                  >
                    <i className='bx bx-trash' />
                  </span>
                </td>
              </tr>
            ))}
          </MDBTableBody>
          <MDBTableBody>
            {extraOrder.map((extraOrders, index) => (
              <tr key={index}>
                <td>{extraOrders.dish_price_id.dish_id.dish_name}</td>
                <td>
                  <Button
                    variant='success'
                    size='sm'
                    onClick={() =>
                      handleExtraOrderDecrement(extraOrders.uniqueId)
                    }
                    disabled={extraOrders.quantity <= 1}
                  >
                    -
                  </Button>
                  <span className='span'>{extraOrders.quantity || 1}</span>
                  <Button
                    variant='success'
                    size='sm'
                    onClick={() =>
                      handleExtraOrderIncrement(extraOrders.uniqueId)
                    }
                  >
                    +
                  </Button>
                </td>
                <td>₱{extraOrders.dish_price_id.price}</td>
                <td colSpan='3' className='remarks'>
                  <span>{extraOrders.extraRemark.remarks}</span>
                </td>
                <td>
                  <span
                    className='spanButton'
                    onClick={() => deleteTableExtraOrderRow(extraOrders.id)}
                  >
                    <i className='bx bx-trash' />
                  </span>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        
        </MDBTable>
      </div>
      <div className='freeContent'>
        {(mainOrder.length >= 1 &&
          mainOrder[0].dish_price_id.dish_id.supplier.main_dish_free ===
          true) ||
          (sideOrder.length >= 1 &&
            sideOrder[0].dish_price_id.dish_id.supplier.side_dish_free ===
            true) ? (
          <h5>Note:</h5>
        ) : null}
      </div>
      <div className='free'>
        {mainOrder.length >= 1 &&
          mainOrder[0].dish_price_id.dish_id.supplier.main_dish_free === true ? (
          <h5>
            {mainOrder[0].dish_price_id.dish_id.dish_name} is free for first
            order
          </h5>
        ) : null}
        {sideOrder.length >= 1 &&
          sideOrder[0].dish_price_id.dish_id.supplier.side_dish_free === true ? (
          <h5>
            {sideOrder[0].dish_price_id.dish_id.dish_name} is free for first
            order
          </h5>
        ) : null}
      </div>
      <div className='modalFooter'>
        <div className='leftFooter'>
          <div className='total'>
            <h5>Total Amount Due: ₱{total}</h5>
          </div>
        </div>
        <div className='rightFooter'>
          <Button 
            type='submit' 
            className='btn btn-primary d-flex align-items-center' 
            onClick={HandleOrder} 
            disabled={extraOrder.length !== 0 || sideOrder.length !== 0 || mainOrder.length !==0 ? false : true}
            style={{backgroundColor: extraOrder.length !== 0 || sideOrder.length !== 0 || mainOrder.length !==0 ? null : '#333333'}}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-1" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
            Confirm
          </Button>
          <span id='ws-id'></span>
        </div>
      </div>
    </div>
  );
};

export default Order;
